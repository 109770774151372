import * as S from "../../../pages/HomworkFeedbackPage/HomeworkFeedbackPage.style";
import { HomeworkFeedbackCard } from "./HomeworkFeedbackCard/HomeworkFeedbackCard";
import { additional, ArrowRightLine } from "@teamsparta/design-system";
import { isMobile } from "mobile-device-detect";

export const HomeworkFeedback = ({ data }) => {
  return (
    <>
      {/*국비지원 강의가 있을 경우*/}
      {data?.length > 0 ? (
        <>
          <S.Explanation>
            숙제 제출 후 최대 24시간 내 제출한 숙제를 검토합니다. <br />
            정상 제출이 확인되면 영업일 기준 2~3일 이내 담당 튜터가 1:1 맞춤
            피드백을 보내드립니다.
            <br />
            <br />
            *2025년 2월 25일 이후에 1:1 실습 피드백을 신청했다면 [내 강의실{" "}
            {">"}
            강의 수강하기]에서 피드백을 확인하실 수 있어요.
          </S.Explanation>
          <S.ContentWrapper>
            {data.map((item) => {
              const homeworks = item.homeworks[0];
              const endPoint = `/enrolleds/${homeworks?.enrolled_homework.enrolled_id}/rounds/${homeworks?.enrolled.round_id}/roadmap`;
              return (
                <>
                  <S.SubjectTitle
                    onClick={() => (window.location.href = endPoint)}
                  >
                    {item.course_title}
                  </S.SubjectTitle>
                  {item.homeworks
                    .filter(
                      (homework) =>
                        homework.submitted_answer !== "pass by system"
                    )
                    .map((homework) => (
                      <HomeworkFeedbackCard homework={homework} />
                    ))}
                </>
              );
            })}
          </S.ContentWrapper>
        </>
      ) : (
        <S.NoHomework>
          <S.NoticeWrapper>
            <S.NoticeSubtitle>
              <NoticeIcon />
              앗! 수강하시는 강의 중에 1:1 숙제 피드백이 지원되는 강의가 없어요.
            </S.NoticeSubtitle>
            <S.NoticeContent isList={true}>
              숙제 1:1 피드백은 국비지원 강의를 수강할 때만 지원하는 기능이에요.
              <br />
              튜터의 피드백으로 내 프로젝트에 완성도를 더하고 싶다면, 국비지원
              강의를 확인해 보세요!
            </S.NoticeContent>
          </S.NoticeWrapper>
        </S.NoHomework>
      )}
    </>
  );
};

const NoticeIcon = () => {
  const size = isMobile ? 16 : 20;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12.7951" r="9" stroke="#5F666B" strokeWidth="2" />
      <rect
        x="13"
        y="9.54507"
        width="2"
        height="2"
        rx="1"
        transform="rotate(-180 13 9.54507)"
        fill="#5F666B"
      />
      <path
        d="M12 17.0951L12 11.4351"
        stroke="#5F666B"
        strokeWidth="1.75"
        strokeLinecap="round"
      />
    </svg>
  );
};
