import { IN_HOUSE_URL } from "../../../../_consts";
import { logout } from "@teamsparta/auth-frontend";

export const sccAnchor = {
  key: "scc",
  name: "스파르타코딩클럽",
  href: process.env.REACT_APP_SCC_MAIN_URL,
  brand: "온라인",
};

/// 추가
const nbAnchor = {
  key: "nb",
  name: "내일배움캠프",
  href: "https://nbcamp.spartacodingclub.kr/",
  brand: "내일배움캠프",
};

export const hanghaeAnchor = {
  key: "hanghae",
  name: "항해99",
  href: "https://hanghae99.spartacodingclub.kr/",
  brand: "항해",
};

export const bizAnchor = {
  key: "bisness",
  name: "기업교육",
  href: "https://b2b.spartacodingclub.kr/",
  brand: "기업교육",
};

const intellipickAnchor = {
  key: "intellipick",
  name: "스파르타 커리어",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "인텔리픽",
};

export const aiClub = {
  key: "aitc",
  name: "AITC 자격증",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/aitc`,
  brand: "온라인",
};

export const studyClub = {
  key: "study",
  name: "스터디클럽",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/study/hub`,
  brand: "온라인",
};

export const freeboardAnchor = {
  key: "freeboard",
  name: "게시판",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/community`,
  brand: "커뮤니티",
};
/// 추가

const catalogAnchor = {
  key: "catalogv3",
  name: "전체 강의",
  href: IN_HOUSE_URL.상품탐색,
  brand: "온라인",
};

const catalogMobileAnchor = {
  key: "catalogv3",
  name: "전체 강의",
  href: IN_HOUSE_URL.상품탐색,
  brand: "온라인",
};

const kdtAnchor = {
  key: "kdt",
  name: "부트캠프",
  href: "https://nbcamp.spartacodingclub.kr/",
  brand: "온라인",
  tag: "국비",
};

const intellipickMobileAnchor = {
  key: "intellipick",
  name: "스파르타 커리어",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "인텔리픽",
  mobileText: "신입 개발자 채용 공고를 한 곳에서",
};

export const blogAnchor = {
  key: "blog",
  name: "블로그",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/blog`,
  brand: "블로그",
};

export const communityAnchor = {
  key: "community",
  name: "커뮤니티",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/community`,
  brand: "커뮤니티",
};

export const eventAnchor = {
  key: "event",
  name: "이벤트",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/event`,
  brand: "이벤트",
};

export const exhibitionAnchor = {
  key: "exhibition",
  name: "수강생 작품",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/exhibition`,
  brand: "수강생 작품",
};

export const faqAnchor = {
  key: "faq",
  name: "고객센터",
  href: "https://support.spartacodingclub.kr/",
};

export const BizAnchor = {
  key: "bisness",
  name: "기업 서비스",
  onClick: () => {},
  brand: "기업 서비스",
};

export const bizEducationAnchor = {
  key: "bizEducation",
  name: "기업 교육",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/b2b`,
  brand: "기업 교육",
  isSub: true,
};

export const bizOutsourcingAnchor = {
  key: "bizOutsourcing",
  name: "외주 개발",
  href: "https://sparta-builders.com/",
  brand: "외주 개발",
  isSub: true,
};

export const bizRecruitAnchor = {
  key: "bizRecruit",
  name: "신입 개발자 채용",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "신입 개발자 채용",
  isSub: true,
};

export const voucherAnchor = {
  key: "voucher",
  name: "수강권",
  href: `/mypage/myvoucher`,
};
export const classroomAnchor = {
  key: "classroom",
  name: "내 강의실",
  href: IN_HOUSE_URL.신_내강의실,
};

export const registrationStatusAnchor = {
  key: "registrationStatus",
  name: "국비 신청 내역",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/kdc/registration_status`,
};

export const homeworkFeedbackAnchor = {
  key: "homework",
  name: "숙제 피드백",
  href: `/homework/feedback`,
};

export const certificateAnchor = {
  key: "certificate",
  name: "증명서",
  href: `/mypage/mycertificate`,
};
export const basicInfoAnchor = {
  key: "basicInfo",
  name: "계정",
  href: `/mypage/mybasicinfo`,
};

export const pointAnchor = {
  key: "point",
  name: "포인트",
  href: `/mypage/mypoint`,
};

export const couponAnchor = {
  key: "coupon",
  name: "쿠폰",
  href: `/mypage/mycoupon`,
};

export const logoutAnchor = {
  key: "logout",
  name: "로그아웃",
  onClick: logout,
};

export const bizDropdownList = [
  bizEducationAnchor,
  bizOutsourcingAnchor,
  bizRecruitAnchor,
];

// 추가
export const topAnchors = [
  sccAnchor,
  nbAnchor,
  hanghaeAnchor,
  bizAnchor,
  intellipickAnchor,
];

export const topRightAnchors = [faqAnchor];

export const mainAnchor = [
  aiClub,
  studyClub,
  catalogAnchor,
  freeboardAnchor,
  blogAnchor,
  eventAnchor,
  exhibitionAnchor,
];

export const mobileMainAnchor = [
  catalogMobileAnchor,
  kdtAnchor,
  communityAnchor,
  blogAnchor,
  eventAnchor,
  exhibitionAnchor,
];

export const mobileBottomAnchors = [intellipickMobileAnchor];

export const mobileMyPageAnchors = [
  classroomAnchor,
  registrationStatusAnchor,
  voucherAnchor,
  certificateAnchor,
  homeworkFeedbackAnchor,
  basicInfoAnchor,
];

// mobile gnb
export const mobileGnbModelV3 = {
  nb: {
    pageName: "국비지원",
  },
  event: {
    pageName: "커뮤니티",
  },
  exhibition: {
    pageName: "커뮤니티",
  },
  community: {
    pageName: "커뮤니티",
  },
  blog: {
    pageName: "커뮤니티",
  },
  catalog: {
    pageName: "전체 강의",
  },
  aitc: {
    pageName: "AITC 자격증",
  },
  study: {
    pageName: "스터디클럽",
  },
  mypage_mypoint: {
    pageName: "포인트",
  },
  mypage_mycoupon: {
    pageName: "쿠폰",
  },
  mypage_myvoucher: {
    pageName: "수강권",
  },
  mypage_mycertificate: {
    pageName: "증명서",
  },
  mypage_mybasicinfo: {
    pageName: "계정",
  },
  homework_feedback: {
    pageName: "숙제 피드백",
  },
  // kdc: {
  //   pageName: "국비 신청 내역",
  // },
};
