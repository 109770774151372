import { history } from "../../../../../_helpers";
import { sendLog } from "../../../../businesslogics/logging";
import { ArrowRightSmall } from "../Icons/Icons";
import * as S from "./LeftMenu.style";
import { logout } from "@teamsparta/auth-frontend";

export const LeftMenu = ({
  point,
  couponCount,
  voucherCount,
  userEmail,
  userPhone,
}) => {
  const myPageInfo = [
    { name: "포인트", detail: point + "원" },
    { name: "쿠폰", detail: couponCount.replace("없음", "0장") },
    { name: "수강권", detail: !!voucherCount ? voucherCount + "장" : "" },
    { name: "증명서", detail: "" },
    { name: "자주 묻는 질문", detail: "" },
    { name: "공지사항", detail: "" },
    { name: "계정", detail: "" },
  ];

  const areaVisibleHandler = (area) => {
    history.push(`/mypage/${area}`);
  };

  const menuHandler = (name) => {
    if (name === "포인트") {
      sendLog(
        "scc_mypagePage_click_button",
        {
          button_text: name,
          button_name: "마이페이지 메뉴버튼",
          next_page: "포인트 페이지",
          next_href: "/mypoint",
        },
        "",
        true
      );
      areaVisibleHandler("mypoint");
      return;
    }
    if (name === "쿠폰") {
      sendLog(
        "scc_mypagePage_click_button",
        {
          button_text: name,
          button_name: "마이페이지 메뉴버튼",
          next_page: "쿠폰 페이지",
          next_href: "/mycoupon",
        },
        "",
        true
      );
      areaVisibleHandler("mycoupon");
      return;
    }
    if (name === "계정") {
      sendLog(
        "scc_mypagePage_click_button",
        {
          button_text: name,
          button_name: "마이페이지 메뉴버튼",
          next_page: "계정관리 페이지",
          next_href: "/mybasicinfo",
        },
        "",
        true
      );
      areaVisibleHandler("mybasicinfo");
      return;
    }
    if (name === "수강권") {
      sendLog(
        "scc_mypagePage_click_button",
        {
          button_text: name,
          button_name: "마이페이지 메뉴버튼",
          next_page: "수강권 페이지",
          next_href: "/myvoucher",
        },
        "",
        true
      );
      voucherCount
        ? areaVisibleHandler("myvoucher")
        : (window.location.href = "/mypage/myvoucher");
      return;
    }
    if (name === "증명서") {
      sendLog(
        "scc_mypagePage_click_button",
        {
          button_text: name,
          button_name: "마이페이지 메뉴버튼",
          next_page: "증명서 페이지",
          next_href: "/mycertificate",
        },
        "",
        true
      );
      areaVisibleHandler("mycertificate");
      return;
    }
    if (name === "자주 묻는 질문") {
      sendLog(
        "scc_mypagePage_click_button",
        {
          button_text: name,
          button_name: "마이페이지 메뉴버튼",
          next_page: "자주 묻는 질문 페이지",
          next_href: "https://support.spartacodingclub.kr/faqs",
        },
        "",
        true
      );
      window.open("https://support.spartacodingclub.kr/faqs", "_blank");
      return;
    }
    if (name === "공지사항") {
      sendLog(
        "scc_mypagePage_click_button",
        {
          button_text: name,
          button_name: "마이페이지 메뉴버튼",
          next_page: "공지사항 페이지",
          next_href: "https://support.spartacodingclub.kr/notices",
        },
        "",
        true
      );
      window.open("https://support.spartacodingclub.kr/notices", "_blank");
      return;
    }
  };
  return (
    <S.LeftMenuWrapper>
      <S.MenuContainer>
        {myPageInfo.map((info) => (
          <S.MenuDiv
            key={`mypage_leftmenu_${info.name}`}
            onClick={() => menuHandler(info.name)}
          >
            <S.MenuContent>
              <S.MenuTitle>{info.name}</S.MenuTitle>
              <S.MenuInfoContainer>
                <S.MenuInfo>{info.detail}</S.MenuInfo>
                <S.MenuGoIcon>
                  <ArrowRightSmall color={"#81898F"} />
                </S.MenuGoIcon>
              </S.MenuInfoContainer>
            </S.MenuContent>
            {info.name === "계정" && (
              <S.UserInfoContainer data-testid="myAccount-info">
                <S.UserInfo>{userEmail}</S.UserInfo>
                <S.UserInfo>{userPhone}</S.UserInfo>
              </S.UserInfoContainer>
            )}
          </S.MenuDiv>
        ))}
      </S.MenuContainer>
      <S.LogoutCta
        onClick={() => {
          sendLog(
            "scc_mypagePage_click_button",
            {
              button_text: "로그아웃",
              next_href: "https://spartacodingclub.kr",
            },
            "",
            true
          );
          logout();
        }}
      >
        로그아웃
      </S.LogoutCta>
    </S.LeftMenuWrapper>
  );
};
