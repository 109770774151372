import { setAuthReactQuery } from "@teamsparta/auth-frontend";
import { Alert } from "antd";
import classNames from "classnames/bind";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { alertActions } from "./_actions";
import { history } from "./_helpers";
import styles from "./App.module.scss";
import {
  AccountEditPage,
  LoginPage,
  PaymentDonePage,
  PaymentPrequestionPage,
  ResetPasswordPage,
  RoadmapPage,
} from "./Pages";
import { AccountReadPage } from "./Pages/AccountReadPage";
import { CheckinPage, CheckinPageEn } from "./Pages/CheckinPage";
import { CheckoutPage, CheckoutPageEn } from "./Pages/CheckoutPage";
import { ClassroomPageEn } from "./Pages/ClassroomPage/ClassroomPageEn";
import { FinishPageEn } from "./Pages/FinishPage";
import { HomeworkPage, HomeworkPageEn } from "./Pages/HomeworkPage";
import LecturePage from "./Pages/LecturePage/LecturePage";
import LecturePageEn from "./Pages/LecturePage/LecturePageEn";
import { LoginPageEn } from "./Pages/LoginPage/LoginPageEn";
import { TokenPage } from "./Pages/LoginPage/TokenPage";
import { NPSPageEn } from "./Pages/NPSPage";
import { PaymentPage_v2 } from "./Pages/PaymentPage_v2";
import { PaymentWait } from "./Pages/PaymentPage_v2/PaymentWait";
import { PrehomeworkPage } from "./Pages/PrehomeworkPage";
import { RcmdDashboardPage } from "./Pages/RcmdDashboardPage";
import { RcmdPage } from "./Pages/RcmdPage";
import { RetakePage } from "./Pages/RetakePage";
import { RetrospectivePage } from "./Pages/RetrospectivePage";
import { RoadmapPageEn } from "./Pages/RoadmapPage/RoadmapPageEn";
import { getCookie } from "./v2/_helper";
import { PhoneVerificationDone } from "./v2/components/organisms/PhoneVerification";
import PrivateRoute from "./v2/hooks/PrivateRoute";
import { B2bAdminNPSPage } from "./v2/pages/B2bAdminNPSPage/B2bAdminNPSPage";
import { B2bNPSPage } from "./v2/pages/B2bNPSPage/B2bNPSPage";
import { B2bPage } from "./v2/pages/B2bPage";
import { B2bPageV2 } from "./v2/pages/B2bPage/B2bPageV2";
import { B2bPageV3 } from "./v2/pages/B2bPage/B2bPageV3";
import { CheckinPageV2 } from "./v2/pages/CheckinPage";
import { ClassroomPageV2 } from "./v2/pages/ClassroomPage";
import { CodingRacePage } from "./v2/pages/CodingRacePage";
import CompleteRecommendPage from "./v2/pages/CompleteRecommendPage/CompleteRecommendPage";
import { CourseRcmdPage } from "./v2/pages/CourseRcmdPage";
import { CourseRcmdA } from "./v2/pages/CourseRcmdPage/CourseRcmdA";
import { CourseRcmdB } from "./v2/pages/CourseRcmdPage/CourseRcmdB";
import { CourseRcmdChang } from "./v2/pages/CourseRcmdPage/CourseRcmdChang";
import { CourseRcmdForDeveloper } from "./v2/pages/CourseRcmdPage/CourseRcmdForDeveloper";
import { CourseRcmdFree } from "./v2/pages/CourseRcmdPage/CourseRcmdFree";
import { CourseRcmdGeneral } from "./v2/pages/CourseRcmdPage/CourseRcmdGeneral";
import { CourseRcmdHanghae } from "./v2/pages/CourseRcmdPage/CourseRcmdHanghae";
import CreditIntroHomeworkPage from "./v2/pages/CreditIntroHomeworkPage/CreditIntroHomeworkPage";
import { EvalutaionPage } from "./v2/pages/EvaluationPage/EvalutaionPage";
import { EvaluationResultPage } from "./v2/pages/EvalutaionResultPage/EvaluationResultPage";
import { FinishPage } from "./v2/pages/FinishPage";
import FreeFinishPage from "./v2/pages/FreeFinishPage/FreeFinishPage";
import { LastNpsPage } from "./v2/pages/FreeNpsPage";
import { HanghaeOrientationInvitationPage } from "./v2/pages/HanghaeOrientationInvitationPage";
import { HomeworkAnswerPage } from "./v2/pages/HomeworkAnswerPage/HomeworkAnswerPage";
import { HomeworkPageV3 } from "./v2/pages/HomeworkPageV3/HomeworkPageV3";
import { HomeworkFeedbackPage } from "./v2/pages/HomworkFeedbackPage/HomeworkFeedbackPage";
import KDTRecommendFinishPage from "./v2/pages/KDTRecommendFinishPage/KDTRecommendFinishPage";
import { ToastHub } from "./v2/pages/LecturePage/LecLNB/Toast/Toast";
import { LecturePageV3 } from "./v2/pages/LecturePageV3/LecturePageV3";
import LoginPageV2 from "./v2/pages/LoginPageV2/LoginPageV2";
import { LogoutPage } from "./v2/pages/LogoutPage";
import { MobileLecReportPage } from "./v2/pages/MobileLecReportPage/MobileLecReportPage";
import { MyPage } from "./v2/pages/MyPage/MyPage";
import { NBCampAttendPage } from "./v2/pages/NBCampAttendPage";
import { NBCampAttendRecordPage } from "./v2/pages/NBCampAttendRecordPage";
import { NbQuizPage } from "./v2/pages/NbQuizPage";
import { NbQuizGiftPage } from "./v2/pages/NbQuizPage/NbQuizGiftPage";
import { NbQuizResultPage } from "./v2/pages/NbQuizPage/NbQuizResultPage";
import { NPSPage } from "./v2/pages/NPSPage";
import NpsPageV3 from "./v2/pages/NPSPageV3/NPSPageV3";
import { PaymentVBankPage } from "./v2/pages/PaymentPage";
import { RcmdFreePage } from "./v2/pages/RcmdFreePage/RcmdFreePage";
import { RegNoPage } from "./v2/pages/RegNoPage";
import { RoadmapPageV2 } from "./v2/pages/RoadmapPage";
import { SecretStorePage } from "./v2/pages/SecretStorePage";
import SharePage from "./v2/pages/SharePage/SharePage";
import { SpalandCheckinPage } from "./v2/pages/SpalandCheckinPage/SpalandCheckinPage";
import { SparthonPage } from "./v2/pages/SparthonPage";
import { TestPage } from "./v2/pages/TestPage";
import { VideoPage } from "./v2/pages/VideoPage/VideoPage";
import { WILPage } from "./v2/pages/WILPage/WILPage";
import { logout } from "@teamsparta/auth-frontend";

const cx = classNames.bind(styles);

class App extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;

    history.listen((/* location, action */) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
    const userinfo = getCookie("userinfo");
    if (userinfo && userinfo.length > 2000) {
      logout({stay: true});
    }
    setAuthReactQuery({
      useQuery: useQuery,
      useMutation: useMutation,
      useQueryClient: useQueryClient,
    });
  }

  render() {
    // Sentry.init({
    //   dsn:
    //     "https://ed09f886df824395bc8b9a4eb8488344@o458696.ingest.sentry.io/5479933",
    // });
    const { alert } = this.props;
    return (
      <div className={cx("wrapper")}>
        {alert.message && (
          <Alert banner message={alert.message} type={alert.type} />
        )}
        <ToastHub />
        <Router history={history}>
          <Switch>
            <PrivateRoute
              exact
              path={`/`}
              component={() => {
                history.replace("/classroom");
                return null;
              }}
            />

            {/* 씀 */}
            <Route path={`/logout`} component={LogoutPage} />
            {/* 씀 */}
            <Route path={`/login`} component={LoginPage} />
            {/* 씀 */}
            <Route path={`/login_v2`} component={LoginPageV2} />
            {/* 씀 */}
            <Route path={`/token`} component={TokenPage} />
            {/* 씀 */}
            <Route path={`/signup`} component={LoginPage} />
            {/* 씀 */}
            <Route path={`/signup_kko`} component={LoginPage} />
            <Route exact path={`/accounts/edit`} component={AccountEditPage} />
            <Route exact path={`/accounts/read`} component={AccountReadPage} />
            <Route path={`/credit/orientation`} component={VideoPage} />
            <Route path={`/b2b/apply/v3`} component={B2bPageV3} />
            <Route path={`/b2b/apply/v2`} component={B2bPageV2} />
            <Route path={`/b2b/apply`} component={B2bPage} />
            {/* 씀 */}
            <Route
              path={`/accounts/edit/:email/:token`}
              component={ResetPasswordPage}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/payment/prequestion/:course_id/:round_id`}
              component={PaymentPrequestionPage}
            />
            <PrivateRoute path={`/payment/finish`} component={SparthonPage} />
            <PrivateRoute
              path={`/payment/vbank/:order_no`}
              component={PaymentVBankPage}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/payment/done/:course_id`}
              component={PaymentDonePage}
            />
            <PrivateRoute path={`/nb/quiz`} component={NbQuizPage} />
            <PrivateRoute path={`/nb/result`} component={NbQuizResultPage} />
            {/* 씀 */}
            <PrivateRoute path={`/nb/gift`} component={NbQuizGiftPage} />
            {/* 안 씀 */}
            <PrivateRoute
              path={[`/payment/:course_id/:round_id`, `/payment/:course_id`]}
              component={PaymentPage_v2}
            />
            <PrivateRoute
              path={`/paymentwait/:order_no/:course_id/:round_id`}
              component={PaymentWait}
            />
            <PrivateRoute path={`/en/login`} component={LoginPageEn} />
            <PrivateRoute path={`/en/signup`} component={LoginPageEn} />
            <PrivateRoute path={`/en/classroom`} component={ClassroomPageEn} />
            <PrivateRoute
              path={`/en/rounds/:round_id/dashboard`}
              component={RoadmapPageEn}
            />
            <PrivateRoute
              path={`/en/rounds/:round_id/lectures/:lecture_id`}
              component={LecturePageEn}
            />
            <PrivateRoute
              path={`/spaland/checkin`}
              component={SpalandCheckinPage}
            />
            <PrivateRoute
              path={`/en/rounds/:round_id/homeworks/:homework_id`}
              component={HomeworkPageEn}
            />
            <PrivateRoute
              path={`/en/rounds/:round_id/checkins/:week/:week_order`}
              component={CheckinPageEn}
            />
            <PrivateRoute
              path={`/en/rounds/:round_id/checkouts/:week/:week_order`}
              component={CheckoutPageEn}
            />
            <PrivateRoute path={`/en/nps/:round_id`} component={NPSPageEn} />
            <PrivateRoute
              path={`/courses/:course_id/prehwk`}
              component={PrehomeworkPage}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/rounds/:round_id/dashboard`}
              component={RoadmapPage}
            />
            <PrivateRoute
              path={`/rounds/:round_id/lectures/:lecture_id`}
              component={LecturePage}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/rounds/:round_id/homeworks/:homework_id`}
              component={HomeworkPage}
            />
            <PrivateRoute
              path={`/rounds/:round_id/checkins/:week/:week_order`}
              component={CheckinPage}
            />
            <PrivateRoute
              path={`/rounds/:round_id/checkouts/:week/:week_order`}
              component={CheckoutPage}
            />
            {/* 씀 */}
            <PrivateRoute path={"/classroom"} component={ClassroomPageV2} />
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/rounds/:round_id/checkins`}
              component={CheckinPageV2}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/rounds/:round_id/roadmap`}
              component={RoadmapPageV2}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/edetails/:detail_id`}
              component={LecturePageV3}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/m/lecture_report/enrolleds/:enrolled_id/edetails/:detail_id`}
              component={MobileLecReportPage}
            />
            <PrivateRoute path={`/enrolleds/test`} component={TestPage} />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/ehomeworks/:enrolled_homework_id`}
              component={HomeworkPageV3}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/answer/:homework_id/ehomeworks/:enrolled_homework_id`}
              component={HomeworkAnswerPage}
            />
            <PrivateRoute
              path={"/homework/feedback/:user_id"}
              component={HomeworkFeedbackPage}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/ewil`}
              component={WILPage}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/nps/free`}
              component={LastNpsPage}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/nps/:week`}
              component={NPSPage}
            />
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/npsV3/:week`}
              component={NpsPageV3}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/b2bNPS`}
              component={B2bNPSPage}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/b2b_admin_nps`}
              component={B2bAdminNPSPage}
            />
            {/* 씀 */}
            <PrivateRoute
              exact
              path={`/courses/:course_id`}
              component={(props) => {
                let course_id = props.match.params.course_id;
                if (course_id === "5f0ae408765dae0006002816") {
                  window.location.href =
                    "https://spartacodingclub.kr/online/web";
                } else if (course_id === "5f0ae408765dae0006002817") {
                  window.location.href =
                    "https://spartacodingclub.kr/online/app";
                } else if (course_id === "5f51cb92b91e36173c722c23") {
                  window.location.href =
                    "https://spartacodingclub.kr/online/pyds";
                } else if (course_id === "5f51cba0e62d08d140b3e5bd") {
                  window.location.href =
                    "https://spartacodingclub.kr/online/dl";
                } else if (course_id === "5f51cba7d76b11f9244d1111") {
                  window.location.href =
                    "https://spartacodingclub.kr/online/sql";
                }
                return null;
              }}
            />
            <PrivateRoute path={`/rcmds/:code`} component={RcmdDashboardPage} />
            {/* 씀 */}
            <PrivateRoute path={`/rcmds`} component={RcmdPage} />
            <PrivateRoute
              exact
              path={`/curriculum`}
              component={() => {
                window.location.href = "https://spartacodingclub.kr/curriculum";
                return null;
              }}
            />
            {/* 씀 */}
            <PrivateRoute path={`/mypage/:section`} component={MyPage} />
            {/* 씀 */}
            <PrivateRoute path={`/mypage`} component={MyPage} />
            {/* 씀 */}
            <PrivateRoute path={`/finish`} component={FinishPage} />

            <PrivateRoute path={`/free_finish`} component={FreeFinishPage} />
            <PrivateRoute
              path={`/kdt_recommend_finish/:enrolled_id`}
              component={KDTRecommendFinishPage}
            />
            {/* 씀 */}
            <PrivateRoute path={`/share`} component={SharePage} />
            {/* 씀 */}
            <PrivateRoute
              path={`/course/rcmd/general`}
              component={CourseRcmdGeneral}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/course/rcmd/A/enrolleds/:enrolled_id`}
              component={CourseRcmdA}
            />
            <PrivateRoute path={`/course/rcmd/B`} component={CourseRcmdB} />
            <PrivateRoute
              path={`/course/rcmd/C`}
              component={CourseRcmdForDeveloper}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/course/rcmd/beginner`}
              component={CourseRcmdGeneral}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/course/rcmd/chang`}
              component={CourseRcmdChang}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/course/rcmd/hanghae`}
              component={CourseRcmdHanghae}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/course/rcmd/hanghae_orientation/invitation`}
              component={HanghaeOrientationInvitationPage}
            />
            {/* 씀 */}
            <PrivateRoute
              path={`/course/rcmd/free`}
              component={CourseRcmdFree}
            />
            {/* 씀 */}
            <PrivateRoute path={`/course/rcmd`} component={CourseRcmdPage} />
            <PrivateRoute path={`/en/finish`} component={FinishPageEn} />
            <PrivateRoute
              path={`/retake/:enrolled_id`}
              component={RetakePage}
            />
            <PrivateRoute
              path={`/phone_verification/done`}
              component={PhoneVerificationDone}
            />
            <PrivateRoute
              path={`/retrospectives/:enrolled_id`}
              component={RetrospectivePage}
            />
            <PrivateRoute
              path={`/secretstore/:course_id/:enrolled_id`}
              component={SecretStorePage}
            />
            <PrivateRoute path={`/regno`} component={RegNoPage} />
            {/* 씀 */}
            <PrivateRoute
              path={`/enrolleds/:enrolled_id/rounds/:round_id/rank`}
              component={CodingRacePage}
            />
            <PrivateRoute
              path={"/nbcamp/attend"}
              component={NBCampAttendPage}
            />
            <PrivateRoute
              path={"/nbcamp/records"}
              component={NBCampAttendRecordPage}
            />
            <PrivateRoute
              path={"/creditintro/homework"}
              component={CreditIntroHomeworkPage}
            />
            <PrivateRoute path={"/rcmdsfree"} component={RcmdFreePage} />
            <PrivateRoute
              path={
                "/enrolleds/:enrolled_id/evaluations/:enrolled_evaluation_id/results"
              }
              component={EvaluationResultPage}
            />
            <PrivateRoute
              path={
                "/enrolleds/:enrolled_id/evaluations/:enrolled_evaluation_id"
              }
              component={EvalutaionPage}
            />
            {/* 씀 */}
            <Route
              path={`/complete_recommend`}
              component={CompleteRecommendPage}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
