import { logout } from "@teamsparta/auth-frontend";
import { authHeader, privateFetch, refreshHeader } from "../_helpers";
import { config } from "../config";
import { getDeviceId } from "../v2/businesslogics/logging";

function logoutOnline(next = "https://spartacodingclub.kr") {
  // localStorage.clear(); 해도되지않을까?
  localStorage.removeItem("user");
  localStorage.removeItem("qualityV3");
  localStorage.removeItem("playbackSpeedV3");
  localStorage.removeItem("fullscreenV3");
  localStorage.removeItem("isMutedV3");
  localStorage.removeItem("volumeV3");
  localStorage.removeItem("captionV3");
  localStorage.removeItem("isAutoPlayOn");
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.includes("videoProgress")) {
      localStorage.removeItem(key);
    }
  }

  logout({ nextPage: next });
}

const setMetaTag = (user_id) => {
  const meta = document.createElement("meta");
  meta.name = "user_id";
  meta.content = user_id;
  document.getElementsByTagName("head")[0].appendChild(meta);
};

function handleLoginResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok || !data.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout({ stay: true });
        // window.location.reload(true)
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout({ stay: true });
        // window.location.reload(true)
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function login(email, password) {
  const device_id = getDeviceId();
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password, device_id }),
    credentials: "include",
  };
  return fetch(`${config.apiUrl}/v1/auth/login`, requestOptions)
    .then(handleLoginResponse)
    .then((response) => {
      // server response includes 'Set-Cookie' headers
      window.enhanced_conversion_data = {
        email: response.user?.email,
        phone: response.user?.phone,
      };
      setMetaTag(response.user._id);
      return response.user;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function loginWithToken(token) {
  const device_id = getDeviceId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ device_id }),
    credentials: "include",
  };
  return fetch(`${config.apiUrl}/v1/auth/login/token`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // server response includes 'Set-Cookie' headers
      return response.user;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function signup(
  name,
  email,
  password,
  phone,
  country_code,
  school,
  region,
  address,
  gender,
  birthYear,
  birthDay,
  marketing_agreement,
  kko_id,
  track,
  nexturltext
) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      name,
      email,
      password,
      phone,
      country_code,
      school,
      region,
      address,
      gender,
      birthyear: birthYear,
      birthday: birthDay,
      marketing_agreement,
      kko_id,
      track,
    }),
  };
  return fetch(`${config.apiUrl}/v1/auth/signup`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (nexturltext) {
        alert(error);
        if (nexturltext === "intellipick") {
          window.location.href = `${config.intellipickUrl}/login`;
        } else if (nexturltext === "hanghae") {
          window.location.href = `${config.hanghaeUrl}/v2/login`;
        }
      }
      throw error;
    });
}

function refresh() {
  const requestOptions = {
    method: "POST",
    headers: refreshHeader(),
    credentials: "include",
  };
  return privateFetch(`${config.apiUrl}/api/auth/refresh`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response.data;
    });
}

function refreshInfo() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return privateFetch(`${config.apiUrl}/v1/accounts/`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // server response includes 'Set-Cookie' headers
      return response.user;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export const userService = {
  login,
  logoutOnline,
  signup,
  refresh,
  loginWithToken,
  refreshInfo,
};
