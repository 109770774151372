import { POINTS, EXPIRATION_POINTS } from "../constants";
import { userConstants } from "../constants";
import { logout } from "@teamsparta/auth-frontend";
import { OPEN_RANDOMBOX } from "../constants/users.constant";
import { getCookie, cookieToObject } from "../../_helper";

const initState = {
  loaded: false,
};

export function points(state = initState, action) {
  switch (action.type) {
    case POINTS.POINTS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case POINTS.POINTS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case POINTS.POINTS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function expirationPoints(state = initState, action) {
  switch (action.type) {
    case EXPIRATION_POINTS.EXPIRATION_POINTS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case EXPIRATION_POINTS.EXPIRATION_POINTS_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case EXPIRATION_POINTS.EXPIRATION_POINTS_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function pointDelta(state = initState, action) {
  switch (action.type) {
    case OPEN_RANDOMBOX.OPEN_RANDOMBOX_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case OPEN_RANDOMBOX.OPEN_RANDOMBOX_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case OPEN_RANDOMBOX.OPEN_RANDOMBOX_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function managed(state = initState, action) {
  switch (action.type) {
    case userConstants.MANAGED_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case userConstants.MANAGED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case userConstants.MANAGED_FAILURE:
      alert(action.error.response.data.message);
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function marketingAgreement(state = initState, action) {
  switch (action.type) {
    case userConstants.MARKETING_AGREEMENT_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case userConstants.MARKETING_AGREEMENT_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case userConstants.MARKETING_AGREEMENT_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

export function userRegNo(state = initState, action) {
  switch (action.type) {
    case userConstants.REG_NO_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case userConstants.REG_NO_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case userConstants.REG_NO_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}

// let user = JSON.parse(localStorage.getItem("user"));
// const initialState = user ? { loggedIn: true, user } : {};
const userinfo = getCookie("userinfo");
const initialState = userinfo
  ? { loggedIn: true, user: cookieToObject(userinfo) }
  : {};

export function authentication2(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        isFetching: true,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        isFetching: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    case userConstants.LOGOUT:
      return {};
    case userConstants.REFRESH_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.REFRESH_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.REFRESH_FAILURE:
      return {};
    case userConstants.REGISTER_REQUEST:
      return {
        isFetching: true,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        isFetching: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.REGISTER_FAILURE:
      alert(action.error);
      let currentUrl = document.location.href;
      let prefix = currentUrl.includes("/en") ? "/en" : "";
      // 한이음 관련해서 redirect 수정
      window.location.href = currentUrl.includes("comkt=hanium")
        ? `${prefix}/signup?next=${encodeURIComponent("/mypage?comkt=hanium")}`
        : `${prefix}/login`;
      return {
        isFetching: false,
      };
    case userConstants.INFO_REFRESH_REQUEST:
      return {
        isFetching: true,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.INFO_REFRESH_SUCCESS:
      return {
        isFetching: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.INFO_REFRESH_FAILURE:
      logout({stay: true});
      return {
        isFetching: false,
      };
    default:
      return state;
  }
}

export function isValidAlpaco(state = initState, action) {
  switch (action.type) {
    case userConstants.VALIDATE_ALPACO_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
      });
    case userConstants.VALIDATE_ALPACO_SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        data: action.response.context,
      });
    case userConstants.VALIDATE_ALPACO_FAILURE:
      return Object.assign({}, state, {
        loaded: false,
      });
    default:
      return state;
  }
}
