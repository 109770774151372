import HackleDevTools from "@hackler/javascript-devtools";
import { createInstance, HackleProvider } from "@hackler/react-sdk";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { IN_HOUSE_URL } from "./_consts";
import { store } from "./_helpers";
import "./index.scss";
import { App } from "./App";
import { config } from "./config";
import * as serviceWorker from "./serviceWorker";
import { getCookie } from "./v2/_helper";
import * as CPL from "@teamsparta/cross-platform-logger";

const { key, debug, auto_track_page_view, auto_open_dev_tool } = config.hackle;

const FLOUNDER_KEY = config.flounder.cognitoKey;
const AMPLITUDE_KEY = config.amplitude.amplitude_key;
// const MIXPANEL_TOKEN = config.mixpanel.mixpanel_token;
const HACKLE_SDK_KEY = config.hackle.key;
const IS_DEV = !config.production;

CPL.initCPLog(FLOUNDER_KEY, AMPLITUDE_KEY, HACKLE_SDK_KEY, IS_DEV);

Sentry.init({
  // production환경만 설정할 경우
  dsn:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SENTRY
      : false,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV === "production" ? "prod" : "local",
  tracesSampleRate: 1.0,
  hideSourceMaps: true,
});

const user = {
  id: typeof window !== "undefined" ? getCookie("device_id") : undefined,
  userId: typeof window !== "undefined" ? getCookie("user_id") : undefined,
  deviceId: typeof window !== "undefined" ? getCookie("device_id") : undefined,
};

const hackleClient = createInstance(key, {
  debug,
  auto_track_page_view,
  devTool: HackleDevTools,
  autoOpenDevTool: auto_open_dev_tool,
});

const redirectRules = [
  { from: "/classroom", to: IN_HOUSE_URL.신_내강의실 },
  {
    from: "^/payment/[^/]+$",
    to: IN_HOUSE_URL.상품탐색,
  },
];

const matchingRule = redirectRules.find((rule) => {
  const pattern = new RegExp(rule.from);
  return pattern.test(window.location.pathname);
});

if (matchingRule) {
  window.location.href = matchingRule.to;
} else {
  const queryClient = new QueryClient();
  const root = document.getElementById("root");
  const reactRoot = createRoot(root);
  reactRoot.render(
    <HackleProvider hackleClient={hackleClient} user={user}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <div id="modal" />
          <App />
        </QueryClientProvider>
      </Provider>
    </HackleProvider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
