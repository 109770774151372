import * as S from "./HomeworkFeedbackPage.style";
import { Gnb } from "../../components/organisms/_layout/gnb/Gnb";
import { HomeworkFeedback } from "../../components/molecules/HomeworkFeedback/HomeworkFeedback";
import { useFeedbackHomeworks } from "../../queries/homework";
import { useParams } from "react-router-dom";
import { useLogFeedbackPageView } from "../../hooks/homework/useLogHomeworkClick";
import { SpartaLogo } from "../../components/molecules/HomeworkFeedback/HomeworkFeedbackCard/HomeworkFeedbackCard.svg";

export const HomeworkFeedbackPage = () => {
  const { user_id } = useParams();
  const { data: homeworksData } = useFeedbackHomeworks(user_id);
  useLogFeedbackPageView();
  return (
    <S.PageContainer>
      <Gnb />
      <S.HomeworkContainer>
        <SpartaLogo />
        <S.HomeworkFeedbackTitle>1:1 실습 피드백</S.HomeworkFeedbackTitle>
        <HomeworkFeedback data={homeworksData?.feedback_homeworks} />
        {(!homeworksData?.feedback_homeworks ||
          homeworksData.feedback_homeworks.length === 0) && (
          <S.FootnoteText>
            *1:1 실습 피드백은 수강 신청 시, 부가 서비스를 함께 신청한 경우
            지원되는 기능이에요.
            <br />
            *2025년 2월 25일 이후에 1:1 실습 피드백을 신청했다면 [내 강의실{" "}
            {">"}
            강의 수강하기]에서 피드백을 확인하실 수 있어요.
          </S.FootnoteText>
        )}
      </S.HomeworkContainer>
    </S.PageContainer>
  );
};
