import { userConstants } from "../_constants";
import { getCookie, cookieToObject } from "../v2/_helper";
import { consoleLogForDev } from "../v2/utils/consoleLog";
import { logout } from "@teamsparta/auth-frontend";
// let user = JSON.parse(localStorage.getItem("user"));
// const initialState = user ? { loggedIn: true, user } : {};
const userinfo = getCookie("userinfo");
const initialState = userinfo
  ? { loggedIn: true, user: cookieToObject(userinfo) }
  : {};
consoleLogForDev(true, "😱 initialState", initialState);

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        isFetching: true,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      // setUserinfo(action.user);
      return {
        isFetching: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    case userConstants.LOGOUT:
      return {};
    case userConstants.REFRESH_REQUEST:
      return {
        loggingIn: true,
        user: state.user,
      };
    case userConstants.REFRESH_SUCCESS:
      return {
        loggedIn: true,
        user: state.user,
      };
    case userConstants.REFRESH_FAILURE:
      return {};
    case userConstants.REGISTER_REQUEST:
      return {
        isFetching: true,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        isFetching: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.REGISTER_FAILURE:
      alert("회원가입에 실패하였습니다.");
      let currentUrl = document.location.href;
      let prefix = currentUrl.includes("/en") ? "/en" : "";
      // 한이음 관련해서 redirect 수정
      // windㅣow.location.href = currentUrl.includes("comkt=hanium") ? `${prefix}/signup?next=mypage&comkt=hanium` : `${prefix}/login`
      window.location.href = currentUrl.includes("comkt=hanium")
        ? `${prefix}/signup?next=${encodeURIComponent("/mypage?comkt=hanium")}`
        : `${prefix}/login`;
      return {
        isFetching: false,
      };
    case userConstants.INFO_REFRESH_REQUEST:
      return {
        isFetching: true,
        loggingIn: true,
        user: state.user,
      };
    case userConstants.INFO_REFRESH_SUCCESS:
      return {
        isFetching: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.INFO_REFRESH_FAILURE:
      logout({stay: true});
      return {
        isFetching: false,
      };
    default:
      return state;
  }
}
