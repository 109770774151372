import { useEffect } from "react";
import { getCookie } from "../../_helper";
import { useIsAuthenticated } from "@teamsparta/auth-frontend";
import * as amplitude from "@amplitude/analytics-browser";

export const useIsLoggedInV2 = () => {
  const { isAuthenticated } = useIsAuthenticated();

  return isAuthenticated;
};
export const useSetAmplitudeUserId = (isLoginModalShow) => {
  const isLoggedInV2 = useIsLoggedInV2();
  useEffect(() => {
    const user_id = getCookie("user_id");
    if (isLoggedInV2 && !isLoginModalShow && !!user_id) {
      amplitude.setUserId(user_id);
    }
  }, [isLoggedInV2, isLoginModalShow]);
};
