import { logout } from "@teamsparta/auth-frontend";
import { config } from "../config";
import { getCookie } from "../v2/_helper";
import { refreshHeader } from "./";

function privateFetch(url, options) {
  let args;
  if (typeof options === undefined) {
    args = [url];
  } else {
    args = [url, options];
  }
  return new Promise((resolve, reject) => {
    fetch(...args).then((response) => {
      if (response.ok) {
        resolve(response);
      } else {
        if (response.status === 401) {
          // access token is expired, need to refresh
          const requestOptions = {
            method: "POST",
            headers: refreshHeader(),
            credentials: "include",
          };
          fetch(`${config.apiUrl}/v1/auth/refresh`, requestOptions).then(
            (response) => {
              response.json().then((data) => {
                if (response.ok) {
                  // successfully refreshed
                  // server response includes 'Set-Cookie' headers

                  const newOptions = Object.assign({}, options);
                  newOptions.headers = Object.assign({}, newOptions.headers);
                  newOptions.headers.Authorization =
                    "Bearer " + getCookie("token");
                  resolve(privateFetch(url, newOptions));
                } else {
                  // refresh failed
                  logout({stay: true});
                }
              });
            }
          );
        } else {
          response.json().then((data) => {
            reject(data.message);
          });
        }
      }
    });
  });
}

export { privateFetch };
