import { atom, useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { history } from "../../../../_helpers";
import { numberWithCommas } from "../../../_helper";
import {
  isMouseOverMyPageIconAtom,
  isMyPageOpenAtom,
  isNavigatorOpenAtom,
} from "../../../stores/mobileMyPageTabOpen";
import { useHistory } from "react-router-dom";
import { sendLog } from "../../logging";
import { useUser } from "../../../pages/LecturePageV3/useInitialize";
import { useLocation } from "react-router-dom";

export const hasRightArrow = (anchor) => anchor.key !== "logout";

export const goLogin = () => {
  const next = encodeURIComponent(window.location.href);
  window.location.href = `${process.env.ONLINE_URL}/login?next=${next}`;
};

export const useMovePage = () => {
  const [, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const [, setIsMyPageOpen] = useAtom(isMyPageOpenAtom);
  useEffect(() => {
    setIsNavigatorOpen(false);
    setIsMyPageOpen(false);
  }, [window.location.pathname]);
};

export const useIsMain = () => {
  const [isMain, setIsMain] = useState(false);
  useEffect(() => {
    setIsMain(window.location.pathname === "/");
  }, [window.location.pathname]);
  return isMain;
};

export const useIsTargetPage = (pathname) => {
  const [isMatch, setIsMatch] = useState(false);
  useEffect(() => {
    setIsMatch(window.location.pathname.split(/(?=\/)/g)[0] === pathname);
  }, [window.location.pathname]);
  return isMatch;
};

export const useGoMain = () => {
  return () => {
    window.location.href = process.env.REACT_APP_SCC_MAIN_URL;
  };
};

export const useRouterTitle = () => {
  const [title, setTitle] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/classroom")) {
      setTitle("내 강의실");
    } else if (location.pathname.includes("/roadmap")) {
      setTitle("강의 수강");
    } else if (location.pathname.includes("/myvoucher")) {
      setTitle("수강권");
    } else if (location.pathname.includes("/mycertificate")) {
      setTitle("증명서");
    } else if (location.pathname.includes("/mybasicinfo")) {
      setTitle("계정");
    } else if (location.pathname.includes("/mycoupon")) {
      setTitle("쿠폰");
    } else if (location.pathname.includes("/mypoint")) {
      setTitle("포인트");
    } else if (location.pathname.includes("/feedback")) {
      setTitle("숙제 피드백");
    }
  }, [location.pathname]);
  return title;
};

export const useUserMeta = (userMeta) => {
  const [userMetaData, setUserMetaData] = useState({
    classroom: "",
    point: "",
    coupon: "",
    voucher: "",
  });
  useEffect(() => {
    if (Object.keys(userMeta).length) {
      let tempObject = {
        classroom: userMeta.is_enrolled_exist ? "수강중" : "",
        point: `${numberWithCommas(userMeta.point_amount)}원`,
        coupon: `${userMeta.active_coupon_count}장`,
        voucher: `${userMeta.active_voucher_count}장`,
      };
      setUserMetaData(tempObject);
    }
  }, [userMeta]);
  return userMetaData;
};

export const logGnbClick = (keyName, data) => {
  const logEventName = getLogEventName(keyName);
  sendCPLog(logEventName, data);
};

export const sendCPLog = (key, data = {}) => {
  try {
    sendLog(key, data, "", true);
  } catch (e) {
    console.log(e);
  }
};
export const useClickAnchor = () => {
  const user = useUser();
  const userId = user?._id;
  const [, setIsMouseOverMyPageIcon] = useAtom(isMouseOverMyPageIconAtom);
  const [__, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  // const navigateWithLogin = useNavigateWithLoginV2();

  return (anchor) => {
    logGnbClick(anchor.key, {
      brand: anchor?.brand ?? "",
      page_url: window.location.href,
      page_title: document.title,
      button_text: anchor.name,
      button_href: anchor.href,
    });
    // 로그인이 필요한 경우 로그인 페이지로 리다이렉트
    if (anchor.isLoginNeededToNavigate && !user) {
      // 로그인 페이지 URL을 프로젝트 설정에 맞게 변경하세요
      window.location.href =
        "/login?returnUrl=" + encodeURIComponent(anchor.href);
      return;
    } else if (anchor.key === "homework") {
      history.push(anchor.href + `/${userId}`);
    } else if (anchor.href) {
      // outlink 인 경우에만 새창에서 띄우고, scc 도메인인 경우 router 로 이동
      if (
        anchor.href.includes("://") &&
        !anchor.href.includes(process.env.ONLINE_URL)
      ) {
        window.location.href = anchor.href;
      } else {
        history.push(anchor.href);
        setIsMouseOverMyPageIcon(false);
        setIsNavigatorOpen(false);
      }
    } else if (!!anchor.onClick) {
      anchor.onClick();
    }
  };
};

export const useOnOutsideClick = (ref, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
};

export const getLogEventName = (keyName) => {
  const gnbAnchorKeys = [
    "scc",
    "catalog",
    "nb",
    "chang",
    "hanghae",
    "blog",
    "community",
    "swcamp",
    "intellipick",
    "ddingdong",
  ];
  const mypageAnchorKeys = [
    "classroom",
    "point",
    "coupon",
    "voucher",
    "certificate",
    "faq",
    "notice",
    "basicInfo",
    "logout",
    "rcmdsfree",
    "registrationStatus",
  ];
  if (gnbAnchorKeys.indexOf(keyName) !== -1) return "scc_gnb_click";
  if (mypageAnchorKeys.indexOf(keyName) !== -1) return "scc_myPage_click";
  if (keyName === "b2b") return "scc_b2b_click";
  if (["bizRecruit", "bizOutsourcing", "bizEducation"].indexOf(keyName) !== -1)
    return "scc_b2b_click";
  return "scc_gnb_click";
};

export const getOutLinkTarget = (keyName) => {
  const newTabLinks = [
    "chang",
    "ddingdong",
    "bizRecruit",
    "bizOutsourcing",
    "kdt",
    "contact",
  ];
  if (newTabLinks.indexOf(keyName) !== -1) return true;
  return false;
};

export const isSectionMovingAtom = atom(false);

export const useFixedPosition = () => {
  const [fixedPosition, setIsFixedPosition] = useState(false);
  const scrollY = useWindowScrollY();
  const lastScroll = useRef(0);
  const [isMovingSection, setIsMovingSection] = useAtom(isSectionMovingAtom);
  const timer = useRef(null);

  useEffect(() => {
    // 페이지 이동 시 초기화
    lastScroll.current = 0;
    setIsFixedPosition(true);

    setIsMovingSection(false);
  }, [history.asPath]);

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  // 스크롤에 따른 fixed 상태 업데이트
  useEffect(() => {
    // 페이지 초기 상태일 때 바로 고정 상태로 설정
    if (lastScroll.current === 0) {
      setIsFixedPosition(true);
      lastScroll.current = scrollY;
      return;
    }

    if (!timer.current) {
      timer.current = setTimeout(() => {
        timer.current = null;
      }, 100);

      if (scrollY <= 5 || scrollY < lastScroll.current) {
        setIsFixedPosition(true);
      } else if (!isMovingSection) {
        setIsFixedPosition(false);
      }
    }
    lastScroll.current = scrollY;
  }, [scrollY, isMovingSection]);

  return fixedPosition;
};

export const scrollYAtom = atom(0);

export const useWindowScrollY = () => {
  const [scrollY, setScrollY] = useAtom(scrollYAtom);
  // const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrollY;
};

export const GnbHandleClickOption = {
  BACK: "BACK",
};

export const useGnbConfig = (model) => {
  const history = useHistory();

  console.log("히스토리", history.location.pathname);

  const pathSegment = history.location.pathname
    .split("/")
    .filter((segment) => segment);

  const firstSegment = pathSegment[0];
  const secondSegment = pathSegment[1];

  let currentModel = model[firstSegment];

  if (firstSegment === "mypage" || firstSegment === "homework") {
    const combinedKey = `${firstSegment}_${secondSegment}`;

    if (model[combinedKey]) {
      currentModel = model[combinedKey];
    } else if (model[firstSegment]) {
      currentModel = model[firstSegment];
    }
  }

  if (!currentModel) return null;

  const modifiedModel = { ...currentModel };

  if (modifiedModel.left) {
    modifiedModel.left = {
      ...modifiedModel.left,
      onClick: _getGnbClickHandler(modifiedModel.left.action, history),
    };
  }

  if (modifiedModel.right) {
    modifiedModel.right = {
      ...modifiedModel.right,
      onClick: _getGnbClickHandler(modifiedModel.right.action, history),
    };
  }

  return modifiedModel;
};

export const _getGnbClickHandler = (action, history = null) => {
  switch (action) {
    case GnbHandleClickOption.BACK:
      return () => {
        history?.back();
      };
    default:
      console.log("should not be reached");
  }
};
