import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie, params } from "../_helper";
import { usersActions } from "../_state/actions";
import { getNextUrl, history } from "../../_helpers";
import {
  useQueryStringParams,
  useIsAuthenticated,
} from "@teamsparta/auth-frontend";

export const useLoginRequired = () => {
  const [logined, setLogined] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authentication?.user);
  useEffect(() => {
    if (params().logined) {
      let token = getCookie("token");
      if (token) {
        return dispatch(usersActions.loginWithToken(token));
      }
    } else {
      if (!user) {
        alert("로그인이 필요합니다.\n로그인 페이지로 이동합니다.");
        return history.replace(`/login?next=${getNextUrl()}`);
      }
    }

    setLogined(true);
  }, []);
  return logined;
};

export const useLoginV2Required = () => {
  const { isAuthenticated, isNotAuthenticated } = useIsAuthenticated();

  const urlQueryString = useQueryStringParams();

  useEffect(() => {
    if (isNotAuthenticated) {
      if (window.location.pathname.includes("/login_v2")) {
        // do nothing
      } else {
        if (urlQueryString.get("login_v2_email")) {
          const _search = window.location.search;
          const search = _search === "" ? "?" : _search;
          history.push(`/login_v2${search}&next=/classroom`);
        } else {
          alert("로그인이 필요합니다.\n로그인 페이지로 이동합니다.");
          const _nextUrl = getNextUrl();
          const nextUrl = _nextUrl === "/" ? "/classroom" : _nextUrl;
          history.push(`/login_v2?next=${nextUrl}`);
        }
      }
    }
  }, [isNotAuthenticated]);

  return isAuthenticated;
};
