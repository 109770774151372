import { config } from "../../config";
import { authHeader } from "../../_helpers";
import axios from "axios";
import { logout } from "@teamsparta/auth-frontend";

const baseURL = config.apiV3Url;
axios.defaults.baseURL = baseURL;
const loggingOut = {};

const privateInstanceV3 = axios.create({
  baseURL,
  timeout: 100000,
  headers: Object.assign({
    "Content-Type": "application/json",
    "X-CLIENT-KEY": "sparta",
  }),
});

let requestCache = {};
privateInstanceV3.interceptors.request.use(
  function (config) {
    if (!config.headers.Authorization) {
      config.headers = Object.assign(
        { "Content-Type": "application/json" },
        authHeader()
      );
    }
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

privateInstanceV3.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    // console.log("-> error.response", error.response);
    if (error.isSkipXHR) {
    } else {
      const { status } = error.response;
      if (status === 401) {
        if (!loggingOut.is) {
          const errorMessage = error.response.data
            ? error.response.data.message
            : "로그인이 만료되었습니다.\n다시 로그인해주시기 바랍니다.";
          loggingOut.is = true;
          logout({stay: true});
        }
      } else if (status === 400) {
        if (error.response.data?.message) {
          alert(error.response.data?.message);
          window.location.href = `/`;
        }
      }
    }

    return Promise.reject(error);
  }
);

export default privateInstanceV3;
